/* eslint-disable react/no-danger */
import React, { createElement } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

import Template from './template'
import { Layout, Card, DownloadPdf, SEO, Image } from '../components'

class PageTemplate extends Template {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  render() {
    const { data } = this.props
    const currentPage = data.wordpressPage
    const allPdf = data.allFile.edges
    const { featuredImage } = currentPage
    let renderBanner = null

    if (featuredImage) {
      const {
        altText,
        localFile: {
          childImageSharp: { fluid },
        },
      } = featuredImage
      renderBanner = (
        <div className="block overflow-hidden max-w-screen max-h-10 bg-no-repeat bg-cover bg-center mb-8">
          <Image fluid={fluid} alt={altText} />
        </div>
      )
    }

    let content = parse(currentPage.content, {
      replace: this.replace,
    })

    content = content.filter(value => value instanceof Object)

    const cards = content.filter(value => value.type === Card)

    let newContent

    if (cards && cards.length !== 0) {
      newContent = []
      const container = createElement(
        'div',
        {
          key: 'volkos',
          className: 'flex justify-around flex-wrap mb-4',
        },
        cards
      )
      let first = true

      for (let i = 0; i < content.length; i++) {
        if (content[i].type === Card) {
          if (first) {
            newContent.push(container)
            first = false
          }
        } else {
          newContent.push(content[i])
        }
      }
    }

    return (
      <Layout>
        <SEO
          title={parse(currentPage.title)}
          description={currentPage.acf.description}
        />
        <h1
          className="title"
          dangerouslySetInnerHTML={{ __html: currentPage.title }}
        />
        {renderBanner}
        <div>{newContent || content}</div>
        <div className="flex flex-wrap justify-around">
          {allPdf.length > 0 &&
            allPdf.map(({ node }, index) => (
              <DownloadPdf key={index} url={node.publicURL} name={node.name}>
                Télécharger le catalogue
              </DownloadPdf>
            ))}
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!, $slug: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      title
      content
      featuredImage: featured_media {
        altText: alt_text
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      acf {
        description
      }
    }
    allWordpressWpMedia {
      edges {
        node {
          id
          source_url
          localFile {
            childImageSharp {
              fixed(width: 500, height: 300) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allFile(
      filter: { extension: { eq: "pdf" }, relativeDirectory: { eq: $slug } }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`
