import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { domToReact } from 'html-react-parser'
import { Link } from 'gatsby'

import { Card, Image } from '../components'
import { createLocalLink } from '../utils'

class Template extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    const {
      data: {
        allWordpressWpMedia: { edges: media },
      },
    } = props

    this.state = {
      media,
    }
  }

  replace = ({ type, name, attribs, children }) => {
    if (type === 'tag') {
      switch (name) {
        case 'div':
          if (attribs.class.includes('wp-block-media-text')) {
            // media + text
            let media = null
            let text = null

            children.forEach(e => {
              if (e.type === 'tag') {
                if (e.name === 'figure') {
                  media = e
                } else if (e.name === 'div') {
                  text = e
                }
              }
            })

            let title
            let content

            text.children.forEach(e => {
              if (e.type === 'tag') {
                if (['h1', 'h2', 'h3', 'h4'].includes(e.name)) {
                  title = domToReact(e.children)
                } else {
                  content = this.replace(e)
                }
              }
            })

            return (
              <Card
                className="mb-4 w-full md:w-1/2"
                image={this.replace(media.children[0])}
                title={title}
              >
                {content}
              </Card>
            )
          }

          if (attribs.class.includes('wp-block-button')) {
            return this.replace(children[0])
          }

          return
        case 'p':
          return <p className="">{domToReact(children)}</p>
        case 'blockquote':
          return (
            <blockquote className="mx-6 pl-6">
              {domToReact(children)}
            </blockquote>
          )
        case 'ul':
          return <ul className="list">{domToReact(children)}</ul>
        case 'a': {
          const href = createLocalLink(attribs.href)

          return (
            <Link className="btn-fill btn-blue" to={href}>
              {domToReact(children)}
            </Link>
          )
        }
        case 'img': {
          const { media } = this.state
          let image = null
          const srcSet = attribs.srcset.split(', ')

          if (media && media.length > 0) {
            image = media.filter(m => {
              let result = null

              srcSet.some(src => {
                const tab = src.split(' ')

                if (
                  m.node.source_url === tab[0] ||
                  (tab[0].includes('-1024x410') &&
                    m.node.source_url === tab[0].replace('-1024x410', ''))
                ) {
                  result = m
                  return true
                }

                return false
              })

              return result
            })
          }

          if (image && image.length === 1) {
            image = image[0].node.localFile
            return (
              <Image fluid={image.childImageSharp.fluid} alt={attribs.alt} />
            )
          }
          return (
            <img alt={attribs.alt} src={attribs.src} srcSet={attribs.srcset} />
          )
        }
        default:
      }
    }
  }
}

export default Template
